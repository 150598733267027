import classnames from 'classnames'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import withLocation, { WithLocationFC } from '../../../hoc/withLocation'
import { setAnalyticsEvent } from '../../../lib/analytics/state'
import { orderByScoreDesc, useProducts } from '../../../lib/products'
import { NoSearchResultsFound } from './NoSearchResultsFound'
import Products from './Products'
import SearchProductsHeader from './SearchProductsHeader'

export interface SearchProductsContainerProps {
  className?: string
}

// TODO: Refetch products when going from guest to logged in user
// TODO: Look into mobile filter issues
// TODO: Look into product section refresh when filtering
// TODO: Fix issue with header showing "searching" when user logs in
const SearchProductsContainer: WithLocationFC<SearchProductsContainerProps> = ({
  className,
  search,
}) => {
  const { query, pagination, listing } = useProducts({
    defaultOrderBy: orderByScoreDesc,
  })
  const { loading } = query
  // fallback to previous data while loading if available
  const data = loading ? query.data ?? query.previousData : query.data

  const anyItemsReturned = !!data?.faceted?.meta.count.all
  useEffect(() => {
    const resultCount = data?.faceted?.meta.count.all ?? 0
    const searchInput =
      (Array.isArray(search?.keywords) ? search?.keywords[0] : search?.keywords) || ''
    if (resultCount === 0) {
      setAnalyticsEvent({
        event: 'search_no_results',
        search_term: searchInput,
      })
    } else {
      setAnalyticsEvent({
        event: 'search',
        search_term: searchInput,
        result_count: resultCount,
      })
    }
  }, [data?.faceted?.meta.count.all, search?.keywords])

  return (
    <div className={className}>
      <SearchProductsHeader anyItemsReturned={anyItemsReturned} loading={loading} search={search} />
      {!anyItemsReturned && <NoSearchResultsFound />}
      <Products
        className={classnames({ noProductsToDisplay: !anyItemsReturned })}
        loading={loading}
        listing={listing}
        pagination={pagination}
        productCount={data?.faceted?.meta.count.all}
        productCountText="SEARCH RESULT"
        products={data?.faceted}
        showHeadingAndBreadcrumbs={false}
        showSortedByRelevance
      />
    </div>
  )
}

SearchProductsContainer.defaultProps = {
  className: '',
  search: null,
}

const StyledSearchProductsContainer = styled(SearchProductsContainer)({
  [Products]: {
    '&.noProductsToDisplay': {
      display: 'none',
    },
  },
})

export default withLocation(StyledSearchProductsContainer)
